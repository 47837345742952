@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

@font-face {
  font-family: 'GE SS Two Light';
  font-style: normal;
  src: url(https://db.onlinewebfonts.com/t/02f502e5eefeb353e5f83fc5045348dc.woff2) format('woff2');
}

@font-face {
  font-family: 'GE SS Two Medium';
  font-style: normal;
  src: url(https://db.onlinewebfonts.com/t/125063e353c1f65c55ba1e3811969217.woff2) format('woff2');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100vh;
}

.video-box {
  position: absolute;
  top: 10px;
  right: 550px;
}

.button-style-call-agora {
  color: #000 !important;
}

.top-buttons-container-call-agora div:first-child button {
  color: #de0202 !important;
}

.top-buttons-container-call-agora div:first-child button:hover {
  color: #fe0909 !important;
}

.button-style-call-agora:hover {
  color: #833dcc !important;
  background-color: #19191a31;
  border-radius: 10px;
}

.live-flag {
  top: 30px !important;
  text-align: center;
}

.videos-container-agora {
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30vw;
  height: 100vh;
  margin: 130px !important;
  margin-top: 70px !important;
}

.agora-video-player-call-agora {
  margin-left: -50%;
}

@media only screen and (max-width: 1440px) {

  /* Audio Call */
  .videos-container-call-agora {
    transition: width 0.3s cubic-bezier(0.95, 0.05, 0.795, 0.035);
    width: 200% !important;
  }

  .agora-video-player-call-agora {
    width: 200% !important;
    margin-left: -219px;
  }

  .top-buttons-container-call-agora {
    width: 200% !important;
  }
}

.social-container-lg {
  display: block;
}

.social-container {
  display: none;
}

@media only screen and (max-width: 768px) {

  /* Live Video */
  .videos-container-agora {
    transition: width 0.3s cubic-bezier(0.95, 0.05, 0.795, 0.035);
    width: 150% !important;
  }

  .video-player-container-agora {
    transition: width 0.3s cubic-bezier(0.95, 0.05, 0.795, 0.035);
    width: 100%;
    margin-left: -300px;
  }

  .agora-video-player-agora {
    transition: width 0.3s cubic-bezier(0.95, 0.05, 0.795, 0.035);
    width: 100% !important;
  }

  .live-flag {
    transition: right 0.3s cubic-bezier(0.95, 0.05, 0.795, 0.035);
    right: 27vw !important;
  }

  /* Audio Call */
  .videos-container-call-agora {
    transition: width 0.3s cubic-bezier(0.95, 0.05, 0.795, 0.035);
    width: 75vw !important;
    margin-right: -500px !important;
  }

  .top-buttons-container-call-agora {
    width: 75vw !important;
    margin-right: -500px !important;
  }

  /* Social Media Icons in Homepage */
  .social-container-lg {
    display: none;
  }

  .social-container {
    display: block;
  }
}